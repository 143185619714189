import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { BiMenu } from "react-icons/bi";
import axios from "axios";
import { firstAuctionInfo } from "../helper/constant_product_details";
import { useNavigate } from "react-router-dom";
const PastAuctionTable = ({ colTitles, data }) => {
  // console.log(data);
  // const [auctionInfo, setAuctionInfo] = useState({
  //   name: "",
  //   id: "",
  //   play_price: 0,
  //   total_bid: 0,
  // });
  // const [auctionDetails, setAuctionsDetails] = useState([]);
  // useEffect(() => {
  //   const data = [];
  //   axios
  //     .get("https://node.thebig.deals/auction-details/product-1")
  //     .then((res) => {
  //       data.push(res.data);
  //       setAuctionsDetails(data);
  //     });
  // }, []);
  // useEffect(() => {
  //   auctionDetails.forEach((element) => {
  //     setAuctionInfo({
  //       name: element.PRODUCT.name,
  //       id: element.PRODUCT.id,
  //       play_price: Math.ceil(element.PLAY_PRICE),
  //       total_bid: element.TOTAL_BOT_BID,
  //     });
  //   });
  // }, [auctionDetails]);
  let allAuction = [];
  const navigate = useNavigate();
  const product1 = firstAuctionInfo;
  allAuction.push(product1);

  data.forEach((dataElement) => {
    allAuction.forEach((auctionElement) => {
      // if (dataElement.product_id === auctionElement.id) {
      const winPrice = auctionElement.total_bid * auctionElement.play_price;
      dataElement.win_price = parseFloat(winPrice.toFixed(2));
      dataElement.auction_name = auctionElement.name;
      dataElement.auction_total_bid = auctionElement.total_bid;
      dataElement.auction_id = auctionElement.auction_id;
      // }
    });
  });
  const showAuctionInfo = (auction_id, product_id) => {
    navigate(`/auctioninfo/${auction_id}/${product_id}`);
  };
  return (
    <div className={`${styles.auctiontablediv}`}>
      <table className={`${styles.auctiontable}`}>
        <thead>
          <tr>
            {colTitles.map((element) => {
              return <th>{element}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((element, index) => {
            return (
              <tr
                key={index}
                onClick={() => {
                  showAuctionInfo(element.auction_id, element.product_id);
                }}
              >
                <td>{element.created_at}</td>
                <td>{element.auction_name}</td>
                <td>{element.win_price}</td>
                <td>{element.auction_total_bid}</td>
                <td>
                  <BiMenu />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PastAuctionTable;
