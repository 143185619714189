import React from "react";
import Helmet from "react-helmet";
import mainStyles from "../../assets/css/main.module.css";
import BackNav from "../helper/BackNav";
import Header from "./Header";
import styles from "./styles.module.css";
import UserDetail from "./UserDetail";
function UserProfile() {
  return (
    <div className={`${styles.profile_page}`}>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <Header />
      <UserDetail />
      {/* <BackNav /> */}
    </div>
  );
}

export default UserProfile;
