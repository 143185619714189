import React from "react";
import { useNavigate } from "react-router-dom";
import mainStyles from "../../assets/css/main.module.css";
import Flag from "../helper/Flag";
import styles from "./styles.module.css";
import { FaEye } from "react-icons/fa";
function UserTable({ data }) {
  // console.log(data);
  const navigate = useNavigate();
  return (
    <div>
      <table className={`${mainStyles.mg_t40} ${styles.user_table}`}>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>country</th>
            <th className={styles.align_center}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((userData, index) => {
            return (
              <tr
                key={index}
                onClick={() => {
                  navigate("/userProfile");
                }}
              >
                <td>{index + 1}</td>
                <td>{userData.name}</td>
                <td>{userData.email}</td>
                <td>
                  <Flag
                    countryCode={userData.country_code}
                    size="sm"
                    className={styles.mg_r10}
                  />
                  {userData.country_name}
                </td>
                <td className={styles.align_center}>
                  <FaEye />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UserTable;
