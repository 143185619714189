import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import thumbnailImage from "../../assets/images/iphone.png";
import {
  AUCTION_BASELINK,
  GET_PRODUCTS,
  TABLE_ROW_PER_PAGE,
} from "../helper/constant";
import styles from "./styles.module.css";
import ProductList from "./ProductList";
import axios from "axios";
function ProductListData() {
  const [productData, setProductData] = useState([]);
  const [filterProductData, setFilterProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(4);
  const [productExist, setProducExist] = useState(false);
  const pageNumbers = [];
  const [alertBox, setAlertBox] = useState(false);

  for (
    let i = 1;
    i <= Math.ceil(filterProductData.length / productPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const indexOfTheLastPost = currentPage * productPerPage;
  const indexOfTheFirstPost = indexOfTheLastPost - productPerPage;
  const currentProducts = filterProductData.slice(
    indexOfTheFirstPost,
    indexOfTheLastPost
  );

  useEffect(() => {
    axios
      .get(AUCTION_BASELINK + GET_PRODUCTS)
      .then((res) => {
        console.log(res.data);
        setProductData(res.data);
        setProducExist(false);
      })
      .catch((err) => {
        console.log("no product");
        setProducExist(true);
      });
  }, [alertBox]);

  useEffect(() => {
    setFilterProductData(productData);
  }, [productData]);

  const filterData = (e) => {
    setFilterProductData(
      productData.filter((data) =>
        JSON.stringify(Object.values(data))
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const handlePageClick = (clickedPageNumber) => {
    setCurrentPage(clickedPageNumber.selected + 1);
  };
  return (
    <div className={styles.product_table_div}>
      <div className={`${styles.table_searchbox_div}`}>
        <input
          type="text"
          className={`${styles.table_searchbox} `}
          placeholder=" Search......"
          onChange={filterData}
        />
      </div>

      {productExist ? (
        <h2 className={styles.emptyTable}>No Products To Display</h2>
      ) : (
        <ProductList
          data={currentProducts}
          setAlertBox={setAlertBox}
          alertBox={alertBox}
        />
      )}
      {!productExist ? (
        <ReactPaginate
          previousLabel={" <"}
          nextLabel={"> "}
          breakLabel={"..."}
          pageCount={pageNumbers.length}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={styles.paginations}
          pageClassName={styles.page_number_box}
          breakClassName={styles.page_number_box}
          previousClassName={styles.pre_next_div}
          nextClassName={styles.pre_next_div}
          activeClassName={styles.active_page}
          pageLinkClassName={styles.page_links}
          previousLinkClassName={styles.page_links}
          nextLinkClassName={styles.page_links}
          breakLinkClassName={styles.page_links}
        />
      ) : null}
    </div>
  );
}

export default ProductListData;
