import React, { useEffect, useState } from "react";
import styles from "../assets/css/main.module.css";
import { FaHome, FaThList, FaChartPie, FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FiPower, FiLogOut } from "react-icons/fi";
import { userLogout } from "./helper/constant";
function NavList() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("dashboard");
  const activePage = styles.current_page;
  const [activeDashboard, setActiveDashboard] = useState("");
  const [activeProductPage, setActiveProductPage] = useState("");
  const [activeAnalyticsPage, setActiveAnalyticsPage] = useState("");
  const [activeUserPage, setActiveUserPage] = useState("");
  useEffect(() => {
    if (currentPage === "dashboard") {
      setActiveProductPage("");
      setActiveUserPage("");
      setActiveAnalyticsPage("");
      setActiveDashboard(activePage);
    } else if (currentPage === "products") {
      setActiveDashboard("");
      setActiveUserPage("");
      setActiveAnalyticsPage("");
      setActiveProductPage(activePage);
    } else if (currentPage === "analytics") {
      setActiveDashboard("");
      setActiveProductPage("");
      setActiveUserPage("");
      setActiveAnalyticsPage(activePage);
    } else if (currentPage === "users") {
      setActiveDashboard("");
      setActiveProductPage("");
      setActiveAnalyticsPage("");
      setActiveUserPage(activePage);
    }
  }, [currentPage]);
  const logout = () => {
    const logoutCheck = userLogout();
    if (logoutCheck) {
      navigate("/login");
    }
  };
  return (
    <div className={styles.navlistdiv}>
      <ul className={styles.navlist}>
        <li
          className={activeDashboard}
          onClick={() => {
            navigate("/dashboard");
            setCurrentPage("dashboard");
          }}
        >
          <FaHome /> <a>Dashboard</a>
        </li>
        <li
          className={activeAnalyticsPage}
          onClick={() => {
            navigate("/analytics");
            setCurrentPage("analytics");
          }}
        >
          <FaChartPie />
          Analytics
        </li>
        <li
          className={activeProductPage}
          onClick={() => {
            navigate("/products");
            setCurrentPage("products");
          }}
        >
          <FaThList /> Products
        </li>
        <li
          className={activeUserPage}
          onClick={() => {
            navigate("/users");
            setCurrentPage("users");
          }}
        >
          <FaUserAlt /> Users
        </li>
        <li onClick={logout}>
          <FiLogOut />
          LogOut
        </li>
      </ul>
    </div>
  );
}

export default NavList;
