import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJs } from "chart.js/auto";
import mainStyle from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
function CountryChart({ chartData }) {
  return (
    <div className={`${styles.country_chart} ${mainStyle.mg_t40}`}>
      <Line data={chartData} />
    </div>
  );
}

export default CountryChart;
