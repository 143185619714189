import React from "react";
import mainStyles from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <div className={mainStyles.headerdiv}>
      <div className={`${styles.profile_title}`}>
        <p
          onClick={() => {
            navigate("/users");
          }}
        >
          <IoReturnUpBackOutline />
        </p>

        <p> Users Profile</p>
      </div>
    </div>
  );
}

export default Header;
