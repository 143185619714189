import React, { useState } from "react";
import WelcomeImage from "./WelcomeImage";
import styles from "../assets/css/main.module.css";
import Login from "./Login";
import Register from "./Register";
import { useNavigate } from "react-router-dom";
function WelcomPage() {
  const [formState, setFormState] = useState("login");
  const navigate = useNavigate();
  // console.log(formState);

  return (
    <div className={styles.flexrow}>
      <WelcomeImage />
      {formState === "login" ? (
        <Login setFormState={setFormState} />
      ) : (
        <Register setFormState={setFormState} />
      )}
    </div>
  );
}

export default WelcomPage;
