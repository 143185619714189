import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import mainStyle from "../../assets/css/main.module.css";
import {
  AUCTION_BASELINK,
  GET_AUCTIONS_API,
  GET_BIDDERS_BID_BY_TIME_INTERVAL,
} from "../helper/constant";
import Chart from "./Chart";
import SelectDate from "./SelectDate";
import mainStyles from "../../assets/css/main.module.css";
import SelectTimeDuration from "./SelectTimeDuration";

function TimeDurationChart() {
  const [auctionChartInfo, setAuctionChartInfo] = useState([]);
  const [xAxisLabel, setXAxisLabel] = useState([]);
  const [uniqueBidder, setUniqueBidder] = useState([]);
  const [newBidder, setNewBidder] = useState([]);
  const [totalBids, setTotalBids] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [auctionTime, setAuctionTime] = useState([]);
  const [selectTimeDate, setTimeDate] = useState(274);
  const [timeDuration, setTimeDuraction] = useState(15);
  useEffect(() => {
    axios
      .get(
        AUCTION_BASELINK +
          GET_BIDDERS_BID_BY_TIME_INTERVAL +
          `/${selectTimeDate}/${timeDuration}`
      )
      .then((res) => {
        setAuctionChartInfo(res.data);
      });
    axios.get(AUCTION_BASELINK + GET_AUCTIONS_API).then((res) => {
      // console.log(res.data, "auctiondata");
      setAuctionData(res.data);
    });
  }, [selectTimeDate, timeDuration]);

  useEffect(() => {
    setXAxisLabel(
      auctionChartInfo.map((e, index) => timeDuration * (index + 1))
    );
    setUniqueBidder(auctionChartInfo.map((e) => e.unique_bidders.length));
    setNewBidder(auctionChartInfo.map((e) => e.new_bidders.length));
    setTotalBids(auctionChartInfo.map((e) => e.count));
    setAuctionTime(
      auctionData.map((e) => {
        let date = new Date(e.created_at);
        return {
          date: date.toString(),
          id: e.id,
        };
      })
    );
  }, [auctionChartInfo, auctionData]);

  const chartData = {
    labels: xAxisLabel,
    datasets: [
      {
        // fill: true,
        label: "new Bidders",
        data: newBidder,
        backgroundColor: "rgb(252, 110, 0 ,0.5)",
        borderColor: "rgb(252, 110, 0,0.5)",
        tension: 0.3,
      },
      {
        // fill: true,
        label: "Unique Bidders",
        data: uniqueBidder,
        backgroundColor: "rgb(84, 139, 253 ,0.7)",
        borderColor: "rgb(84, 139, 253 ,0.7)",
      },
      {
        type: "bar",
        label: "Total Bids",
        data: totalBids,
        backgroundColor: "rgb(127, 252, 0,0.7)",
        borderColor: "rgb(127, 252, 0,0.7)",
      },
    ],
  };

  return (
    // <div>
    <div className={`${styles.chart_backgound}`}>
      <h3 className={styles.title}>Bid & Bidders By Time Duration</h3>
      <div className={`${styles.d_flex} `}>
        <SelectDate
          values={auctionTime}
          heading={"Auction"}
          setTimeDate={setTimeDate}
        />
        <SelectTimeDuration
          heading={"Time Duraction"}
          setTimeDuraction={setTimeDuraction}
        />
      </div>
      <Chart chartData={chartData} />
    </div>
    // </div>
  );
}

export default TimeDurationChart;
