import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AUCTION_BASELINK,
  GET_AUCTIONS_API,
  GET_USER_PER_PRICE,
} from "../helper/constant";
import Chart from "./Chart";
import styles from "./styles.module.css";
import SelectDate from "./SelectDate";
import mainStyles from "../../assets/css/main.module.css";

function PriceBandChart() {
  const [auctionChartInfo, setAuctionChartInfo] = useState([]);
  const [xAxisLabel, setXAxisLabel] = useState([]);
  const [totalBids, setTotalBids] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [auctionTime, setAuctionTime] = useState([]);
  const [selectTimeDate, setTimeDate] = useState(274);

  useEffect(() => {
    axios
      .get(AUCTION_BASELINK + GET_USER_PER_PRICE + `/${selectTimeDate}`)
      .then((res) => {
        setAuctionChartInfo(res.data);
      });
    axios.get(AUCTION_BASELINK + GET_AUCTIONS_API).then((res) => {
      setAuctionData(res.data);
    });
  }, [selectTimeDate]);

  useEffect(() => {
    setAuctionTime(
      auctionData.map((e) => {
        let date = new Date(e.created_at);

        return {
          date: date.toString(),
          id: e.id,
        };
      })
    );
    const data = auctionChartInfo.filter((e, index, arr) => {
      return (
        arr.map((maparr) => maparr.priceslice).indexOf(e.priceslice) == index
      );
    });
    setXAxisLabel(data.map((e) => e.priceslice));
    let price = [];
    auctionChartInfo.forEach((e) => {
      price.push(e.priceslice);
    });
    var obj = {};
    price.forEach((e) => {
      if (!obj[e]) {
        obj[e] = 1;
      } else {
        obj[e]++;
      }
    });

    setTotalBids(Object.values(obj));
  }, [auctionChartInfo]);

  const chartData = {
    labels: xAxisLabel,
    datasets: [
      {
        type: "bar",
        label: "Total Bids",
        data: totalBids,
        backgroundColor: "rgb(0, 252, 86,0.6)",
        borderColor: "rgb(0, 252, 86,0.6)",
      },
    ],
  };
  return (
    // <div>
    <div className={`${styles.chart_backgound}`}>
      <h3 className={styles.title}>Bidders By Price Band</h3>
      <div className={`${styles.d_flex} `}>
        <SelectDate
          values={auctionTime}
          heading={"Auction"}
          setTimeDate={setTimeDate}
        />
      </div>
      <Chart chartData={chartData} />
    </div>
    // </div>
  );
}

export default PriceBandChart;
