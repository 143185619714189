import React from "react";
import userImage from "../../assets/images/maleAvatar.png";
import bannerImage from "../../assets/images/banner2.png";
import mainStyles from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
import UserAuctionTable from "./UserAuctionTable";
import { FaPhoneAlt, FaRegCalendarAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { GiTwoCoins } from "react-icons/gi";
import Flag from "../helper/Flag";
function UserDetail() {
  return (
    <div className={styles.details_div}>
      {/* <div className={`${styles.banner_div}`}>
        <img className={styles.banner_image} src={bannerImage} alt="" />
      </div> */}
      <div>
        <img className={styles.user_image} src={userImage} alt="" />
        <p className={styles.user_name}>Akshat Patel</p>
        <p className={styles.user_email}>
          {" "}
          <MdEmail />
          akshat@gmail.com{" "}
        </p>
        <p className={styles.user_number}>
          <FaPhoneAlt /> +91 6587423659
        </p>
        <p className={styles.user_country}>
          <ImLocation2 /> India <Flag countryCode="IN" size="sm" />
        </p>
      </div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <p className={styles.title}>Play purchases</p>
          <p className={styles.info}>
            <GiTwoCoins />
            1250
          </p>
        </div>
        <div className={styles.card}>
          <p className={styles.title}>Play balance</p>
          <p className={styles.info}>
            <GiTwoCoins />
            564
          </p>
        </div>
        <div className={styles.card}>
          <p className={styles.title}>User since</p>
          <p className={styles.info}>
            <FaRegCalendarAlt />
            Thu Apr 21 2022 18:30:00
          </p>
        </div>
        <div className={styles.card}>
          <p className={styles.title}>Last login</p>
          <p className={styles.info}>
            <FaRegCalendarAlt />
            Thu Apr 21 2022 18:30:00
          </p>
        </div>
      </div>
      <UserAuctionTable />
    </div>
  );
}

export default UserDetail;
