import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";

import { useNavigate } from "react-router-dom";
const MainHeader = () => {
  const active = styles.active;
  const data = new Date();
  // const user = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <div className={styles.headerdiv}>
      <div className={`${styles.mg_t40}`}>
        <p className={styles.title_txt}>Hello </p>
        <p className={styles.dateinfo}>
          Today is {data.toLocaleDateString("en", { dateStyle: "full" })}
        </p>
      </div>
      <div className={`${styles.mg_t40} ${styles.d_flex}`}>
        <div className={`${styles.searchdiv}`}></div>
        <button className={`${styles.projectbtn} ${mainStyles.pointer}`}>
          Add New Project
        </button>
      </div>
    </div>
  );
};

export default MainHeader;
