import React, { useEffect } from "react";
import styles from "./styles.module.css";
import disp3 from "../../assets/images/maleAvatar.png";
import disp1 from "../../assets/images/avatar2.png";
import disp2 from "../../assets/images/avatar3.png";
import { RiUser6Fill, RiAuctionFill } from "react-icons/ri";
import { FaBitcoin } from "react-icons/fa";
const Card = ({ title, cardcolor, value }) => {
  // const task = (taskDone * 100) / numberOfTask;
  // const taskdone = Math.floor(task).toString() + "%";

  return (
    <div id="card" className={`${styles.cards} ${cardcolor}`}>
      <div className={`${styles.imagestack}`}>
        <div className={`${styles.imagediv} ${styles.imageone}`}>
          <img src={disp1} alt="" />
          {/* <RiUser6Fill /> */}
        </div>
        <div className={`${styles.imagediv} ${styles.imagetwo}`}>
          <img src={disp3} alt="" />
          {/* <RiAuctionFill /> */}
        </div>
        <div className={`${styles.imagediv} ${styles.imagethree}`}>
          <img src={disp2} alt="" />
          {/* <FaBitcoin /> */}
        </div>
      </div>
      <p className={`${styles.titlename}`}>{title}</p>
      <p className={`${styles.valuetxt}`}>{value}</p>
      {/* <div className={styles.progressinfo}>
        <p className={`${styles.progresstxt}`}>
          {numberOfTask} Task | {taskdone}
        </p>
        <div className={`${styles.progressdiv}`}>
          <div
            style={{
              width: `${taskdone}`,
            }}
            className={`${styles.progressbar}`}
          ></div>
        </div>
      </div> */}
    </div>
  );
};

export default Card;
