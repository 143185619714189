import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import Loading from "../helper/Loading";
import AuctionInfoTable from "./AuctionInfoTable";
import {
  AUCTION_BASELINK,
  GET_BIDLOG,
  PRODUCT_DETAILS_API,
  TABLE_ROW_PER_PAGE,
} from "../helper/constant";
import ReactPaginate from "react-paginate";
const AuctionInfo = () => {
  document.title = "Bid Log";
  const navigate = useNavigate();
  const param = useParams();
  const [productDetails, setProductDetails] = useState({ name: "" });
  const [productName, setProductName] = useState("");
  const [filterAuctionInfo, setFilterAuctionInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [pageNumbers, setPageNumber] = useState(0);

  const currentLogs = filterAuctionInfo;

  const handlePageClick = (clickedPageNumber) => {
    setCurrentPage(clickedPageNumber.selected + 1);
  };

  useEffect(() => {
    const logedinUser = JSON.parse(localStorage.getItem("user"));
    const userToken = localStorage.getItem("token");
    // console.log(param.productId, "product id ");
    if (logedinUser.saveUser && userToken == null) {
      navigate("/login");
    } else {
      axios.get(AUCTION_BASELINK + PRODUCT_DETAILS_API + "1").then((res) => {
        setProductDetails({ name: res.data.PRODUCT.name });
      });
    }
  }, []);
  useEffect(() => {
    axios
      .get(
        AUCTION_BASELINK +
          GET_BIDLOG +
          `/${param.auctionID}/${TABLE_ROW_PER_PAGE}/${currentPage}`
      )
      .then((res) => {
        setPageData(res.data.data);
        setPageNumber(res.data.totalPages);
      });
  }, [currentPage]);

  useEffect(() => {
    setFilterAuctionInfo(pageData);
    setProductName(productDetails.name);
  }, [pageData]);

  const backToDashboard = () => {
    navigate("/dashboard");
  };

  const filterData = (e) => {
    setFilterAuctionInfo(
      pageData.filter((data) =>
        JSON.stringify(Object.values(data))
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };
  return (
    <div className={`${styles.mg_t40} ${styles.w80}`}>
      <div className={`${styles.auction_div_title}`}>
        <div className={styles.auctioninfo_title_div}>
          <div
            className={`${styles.title_txt} ${styles.backbtn} `}
            onClick={backToDashboard}
          >
            <IoReturnUpBackOutline />
          </div>
          <h3 className={`${styles.bid_log_title} ${styles.auction_title} `}>
            Bid Log | {productName} | ID : {param.auctionID}
          </h3>
        </div>
        <div className={`${styles.table_searchbox_div}`}>
          <input
            type="text"
            className={`${styles.table_searchbox}`}
            placeholder=" Search......"
            onChange={filterData}
          />
        </div>
      </div>
      {filterAuctionInfo.length === 0 ? (
        <div className={`${styles.loading_disp_div}`}>
          <Loading />
        </div>
      ) : (
        <AuctionInfoTable filterAuctionInfo={currentLogs} />
      )}
      {/* <AuctionInfoTable filterAuctionInfo={currentLogs} /> */}
      <div className={styles.paginationsdiv}>
        <ReactPaginate
          previousLabel={" <"}
          nextLabel={"> "}
          breakLabel={"..."}
          pageCount={pageNumbers}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={styles.paginations}
          pageClassName={styles.page_number_box}
          breakClassName={styles.page_number_box}
          previousClassName={styles.pre_next_div}
          nextClassName={styles.pre_next_div}
          activeClassName={styles.active_page}
          pageLinkClassName={styles.page_links}
          previousLinkClassName={styles.page_links}
          nextLinkClassName={styles.page_links}
          breakLinkClassName={styles.page_links}
        />
      </div>
    </div>
  );
};

export default AuctionInfo;
