import React, { useState } from "react";
import styles from "../assets/css/main.module.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import InputError from "./InputError";
import { BASEAPILINK } from "./helper/constant";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./helper/Loading";
function RegisterForm() {
  const [dataLoading, setDataLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState();
  const [passwordColor, setPasswordColor] = useState();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    firstname: Yup.string().required("Required"),
    // middlename: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string().email("invalid email format").required("Required"),
    password: Yup.string()
      .min(8)
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  const navigate = useNavigate();
  const onSubmit = (value) => {
    // console.log(value);
    setDataLoading(true);
    const data = {
      customer: {
        firstname: value.firstname,
        middlename: "",
        lastname: value.lastname,
        email: value.email,
      },
      password: value.password,
    };
    console.log(data);

    //robo1@yopmail.com
    //Globalvox@1234

    const userDetails = {
      firstname: value.firstname,
      lastname: value.lastname,
      email: value.email,
    };

    localStorage.setItem("userDetails", JSON.stringify(userDetails));

    axios
      .post("https://admin.thebig.deals/rest/all/V1/customers", data)
      .then((response) => {
        console.log(response);
        setDataLoading(false);
        navigate("/dashboard");
      });
  };
  const patten =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{14,}$/;
  const patten1 =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;
  const change = (e) => {
    // console.log(patten.test(e.target.value));
    if (e.target.name === "password") {
      if (patten.test(e.target.value)) {
        setPasswordStrength("Strong");
        setPasswordColor(styles.color_green);
      } else if (patten1.test(e.target.value)) {
        setPasswordStrength("Average");
        setPasswordColor(styles.color_orange);
      } else {
        setPasswordColor(styles.color_red);
        setPasswordStrength("Weak");
      }
    }
  };
  return (
    <div className={styles.formdiv}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className={styles.flexcol} onChange={change}>
          <Field
            name="firstname"
            placeholder="First Name"
            className={styles.reginputbox}
          />
          <ErrorMessage name="firstname" component={InputError} />
          <Field
            name="lastname"
            placeholder="Last Name"
            className={styles.reginputbox}
          />
          <ErrorMessage name="lastname" component={InputError} />
          <Field
            className={styles.reginputbox}
            type="email"
            name="email"
            placeholder="Email"
          />
          <ErrorMessage name="email" component={InputError} />
          <div className={styles.password_div}>
            <Field
              className={styles.reginputbox}
              type="password"
              name="password"
              placeholder="Password"
            />
            <p className={`${styles.passwordstrength} ${passwordColor}`}>
              {passwordStrength}
            </p>
          </div>
          <ErrorMessage name="password" component={InputError} />
          <Field
            name="confirmPassword"
            placeholder="Confirm Password"
            className={styles.reginputbox}
            type="password"
          />{" "}
          <ErrorMessage name="confirmPassword" component={InputError} />
          <div className={styles.submitdiv}>
            {dataLoading ? (
              <Loading />
            ) : (
              <button type="submit" className={styles.btn}>
                Register
              </button>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default RegisterForm;
