import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "../assets/css/main.module.css";
import { BASEAPILINK } from "./helper/constant";
import Loading from "./helper/Loading";
import InputError from "./InputError";
function LoginForm() {
  const navigate = useNavigate();
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    saveUser: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("invaid email format").required("Required"),
    password: Yup.string().min(8).required(" Enter Password"),
    // saveUser: Yup.boolean().oneOf([true], "Required"),
  });
  const onSubmit = (values) => {
    //robo1@yopmail.com
    //akshat06@yopmail.com
    //Globalvox@1234
    setDataLoading(true);
    const data = {
      username: values.email,
      password: values.password,
    };

    // axios
    //   .post(BASEAPILINK + "/V1/integration/customer/token", data)
    //   .then((response) => {
    //     // console.log("response:", response.status);
    //     if (response.status === 200) {
    //       localStorage.setItem("token", response.data);
    //       if (values.saveUser) {
    //         localStorage.setItem("user", JSON.stringify(values));
    //         console.log("localStorage");
    //       }
    //       setDataLoading(false);
    //       navigate("/dashboard");
    //     }
    //   })
    //   .catch((err) => {
    //     setInvalidLogin(true);
    //     setDataLoading(false);
    //     navigate("/login");
    //   });

    if (
      data.username === "akshat06@yopmail.com" &&
      data.password == "Globalvox@1234"
    ) {
      localStorage.setItem("token", "jnco0qis99fmkeepskgykc3ui993vppo");
      localStorage.setItem(
        "userDetails",
        "{ firstname:Akshat,lastname:patel,email:akshat06@yopmail.com}"
      );
      if (values.saveUser) {
        localStorage.setItem("user", JSON.stringify(values));
        console.log("localStorage");
      }
      setDataLoading(false);
      navigate("/dashboard");
    }
  };
  return (
    <div className={styles.formdiv}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className={styles.flexcol}>
          <Field
            className={styles.inputbox}
            name="email"
            type="email"
            placeholder="Email"
          />
          <ErrorMessage name="email" component={InputError} />
          <Field
            className={styles.inputbox}
            type="password"
            name="password"
            placeholder="Password"
          />
          <ErrorMessage name="password" component={InputError} />
          <div className={styles.inputcheck}>
            <Field type="checkbox" name="saveUser" id="saved" />{" "}
            <label htmlFor="saved">Remeber me!!</label>
            {/* <ErrorMessage name="saveUser" component={InputError} /> */}
          </div>
          {invalidLogin ? (
            <InputError>Invalid Username or Password</InputError>
          ) : (
            ""
          )}
          <div className={styles.submitdiv}>
            {dataLoading ? (
              <Loading />
            ) : (
              <button type="submit" className={styles.btn}>
                Login
              </button>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default LoginForm;
