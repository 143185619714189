import React from "react";
import styles from "../assets/css/main.module.css";
import avatar from "../assets/images/maleAvatar.png";
function ProfileBox({ firstname, lastname, email }) {
  return (
    <div className={styles.avatardiv}>
      <div className={styles.avatarimg}></div>
      <p className={styles.name}>
        {firstname} {lastname}
      </p>
      <p className={styles.email}>{email}</p>
    </div>
  );
}

export default ProfileBox;
