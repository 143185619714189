import React from "react";
import styles from "./styles.module.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function FormHeader({ title }) {
  const navigate = useNavigate();
  const backToProductList = () => {
    navigate("/products");
  };
  return (
    <div>
      <div className={`${styles.title_div} ${styles.form_title}`}>
        <div
          className={`${styles.title_txt} ${styles.backbtn} `}
          onClick={backToProductList}
        >
          <IoReturnUpBackOutline />
        </div>
        <h3 className={`${styles.title_txt} ${styles.auction_title} `}>
          {title}
        </h3>
      </div>
    </div>
  );
}

export default FormHeader;
