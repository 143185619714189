import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
function Header() {
  const navigate = useNavigate();
  const productForm = () => {
    navigate("/addProduct");
  };

  return (
    <div className={mainStyles.headerdiv}>
      <div className={`${mainStyles.mg_t40}`}>
        <p className={mainStyles.title_txt}> Products</p>
      </div>

      <button
        className={`${mainStyles.btn_style} ${mainStyles.pointer}`}
        onClick={productForm}
      >
        Add Product
      </button>
    </div>
  );
}

export default Header;
