import "./App.css";
import Home from "./components/Home";
import { Route, Routes } from "react-router-dom";
import DashBoardBody from "./components/dashboard/DashboardBody";
import AnalyticsBody from "./components/analytics/AnalyticsBody";
import AuctionInfo from "./components/dashboard/AuctionInfo";
import ProductsBody from "./components/products/ProductBody";
import AddProduct from "./components/products/AddProducts";
import EditProduct from "./components/products/EditProduct";
import UserBody from "./components/users/UserBody";
import UserProfile from "./components/users/UserProfile";
import WelcomPage from "./components/WelcomePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<DashBoardBody />} />
          <Route path="/dashboard" element={<DashBoardBody />} />
          <Route
            path="/auctioninfo/:auctionID/:productId"
            element={<AuctionInfo />}
          />
          <Route path="/analytics" element={<AnalyticsBody />} />
          <Route path="/products" element={<ProductsBody />} />
          <Route path="/addProduct" element={<AddProduct />} />
          <Route path="/editProduct" element={<EditProduct />} />
          <Route path="/users" element={<UserBody />} />
          <Route path="/userProfile" element={<UserProfile />} />
        </Route>
        <Route path="/login" element={<WelcomPage />} />
      </Routes>
    </div>
  );
}

export default App;
