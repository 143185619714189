import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { BiMenu } from "react-icons/bi";
import axios from "axios";
const AuctionTable = ({ colTitles, data }) => {
  // console.log(data);
  return (
    <div className={`${styles.auctiontablediv}`}>
      <table className={`${styles.auctiontable}`}>
        <thead>
          <tr>
            {colTitles.map((element) => {
              return <th>{element}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((element, index) => {
            return (
              <tr key={index}>
                <td>{element.AUCTION_START_DATE_TIME}</td>
                <td>{element.PRODUCT.name}</td>
                {/* <td></td> */}
                <td>{parseInt(element.TOTAL_BOT_BID)}</td>
                <td>
                  <BiMenu />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AuctionTable;
