import axios from "axios";
import { useEffect, useState } from "react";

export var firstAuctionInfo = {
  name: "",
  id: "",
  play_price: 0,
  total_bid: 0,
  auction_id: 0,
};

export const getFristAuctionData = async () => {
  const res = await axios.get(
    "https://node.thebig.deals/auction-details/product-1"
  );
  firstAuctionInfo.id = res.data.PRODUCT.id;
  firstAuctionInfo.name = res.data.PRODUCT.name;
  firstAuctionInfo.play_price = res.data.PLAY_PRICE;
  firstAuctionInfo.total_bid = parseInt(res.data.TOTAL_USER_BID);
  firstAuctionInfo.auction_id = res.data.AUCTION_ID;
  console.log(firstAuctionInfo);
};
