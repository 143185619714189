import React from "react";
import styles from "./styles.module.css";
import ProductForm from "./ProductForm";
import FormHeader from "./FormHeader";
import Helmet from "react-helmet";
function AddProduct() {
  return (
    <div>
      <Helmet>
        <title>Add Product</title>
      </Helmet>

      <FormHeader title={"Add Products"} />
      <ProductForm />
    </div>
  );
}

export default AddProduct;
