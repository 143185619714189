import React from "react";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
import { AUCTION_BASELINK, DELETE_PRODUCTS } from "../helper/constant";
import axios from "axios";
function AlertBox({ setAlertBox, deleteData }) {
  // console.log(deleteData);
  const hideBox = () => {
    setAlertBox(false);
  };

  const deleteProduct = () => {
    axios
      .delete(AUCTION_BASELINK + DELETE_PRODUCTS + `${deleteData.ID}`)
      .then((res) => {
        console.log(res);
        setAlertBox(false);
      });
  };
  return (
    <div className={styles.alert}>
      <div className={styles.alert_box}>
        <h2>Delete this Product ?</h2>
        <h3>Product {deleteData.name}</h3>
        <img
          className={styles.thumbnail_image}
          src={AUCTION_BASELINK + deleteData.image_url.slice(0)}
          alt={deleteData.name + "Image"}
        />
        <p></p>
        <button
          className={`${styles.btn_style} ${mainStyles.pointer}`}
          onClick={deleteProduct}
        >
          Delete
        </button>{" "}
        <button
          className={`${styles.btn_style} ${mainStyles.pointer}`}
          onClick={hideBox}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default AlertBox;
