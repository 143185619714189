import React, { useState } from "react";
import styles from "./styles.module.css";
import thumbnailImage from "../../assets/images/iphone.png";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { AUCTION_BASELINK } from "../helper/constant";
import AlertBox from "./AlertBox";
function ProductList({ data, setAlertBox, alertBox }) {
  const [deleteData, setDeleteData] = useState({});
  return (
    <div>
      <table className={styles.product_table}>
        <thead>
          <tr>
            <th></th>
            <th>thumbnail</th>
            <th>name</th>
            <th>start date</th>
            <th className={styles.align_center}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => {
            const date = new Date(data.auction_start_time);
            const currentDate = new Date();

            return (
              <tr key={index}>
                <td className={styles.p_l10}>{index + 1}</td>
                <td>
                  <img
                    src={AUCTION_BASELINK + data.image_url.slice(1)}
                    alt={data.name + " iamge"}
                    className={styles.thumbnail_image}
                  />
                </td>
                <td>{data.name}</td>
                <td>
                  {date
                    .toString()
                    .replace("(India Standard Time)", "")
                    .replace("GMT+0530", "")}
                </td>
                {date > currentDate ? (
                  <td className={styles.align_center}>
                    <div className={styles.edit_action}>
                      <Link to={"/editProduct"} state={data}>
                        <FaEdit />
                      </Link>

                      <a
                        onClick={() => {
                          setDeleteData(data);
                          setAlertBox(true);
                        }}
                      >
                        <MdDelete />
                      </a>
                    </div>
                  </td>
                ) : (
                  <td className={styles.align_center}>
                    <div className={`${styles.disable}`}>
                      <a>
                        <FaEdit />
                      </a>
                      <a>
                        {" "}
                        <MdDelete />
                      </a>
                      <span className={styles.error_text}>
                        Auction is started
                      </span>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {alertBox ? (
        <AlertBox setAlertBox={setAlertBox} deleteData={deleteData} />
      ) : null}
    </div>
  );
}

export default ProductList;
