import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AUCTION_BASELINK,
  GET_AUCTIONS_API,
  GET_BIDDERS_TOTAL_BIDS_BY_COUNTRY,
} from "../helper/constant";
import CountryChart from "./CountryChart";
import SelectDate from "./SelectDate";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
function BidByCountry() {
  const [bidderInfo, setBidderInfo] = useState([]);
  const [bidInfo, setBidInfo] = useState([]);
  const [totalBid, setTotalBid] = useState([]);
  const [totalBidder, setTotalBidders] = useState([]);
  const [selectTimeDate, setTimeDate] = useState(274);
  const [xAxisLabel, setXAxisLabel] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [auctionTime, setAuctionTime] = useState([]);
  useEffect(() => {
    axios.get(AUCTION_BASELINK + GET_AUCTIONS_API).then((res) => {
      setAuctionData(res.data);
    });
    axios
      .get(
        AUCTION_BASELINK +
          GET_BIDDERS_TOTAL_BIDS_BY_COUNTRY +
          `/${selectTimeDate}`
      )
      .then((res) => {
        setBidInfo(res.data.bidsData);
        setBidderInfo(res.data.biddersData);
      });
  }, [selectTimeDate]);

  useEffect(() => {
    setAuctionTime(
      auctionData.map((e) => {
        let date = new Date(e.created_at);

        return {
          date: date.toString(),
          id: e.id,
        };
      })
    );
    setXAxisLabel(
      bidInfo.map((e) => {
        return e.country_name;
      })
    );
    // console.log(xAxisLabel);
    setTotalBid(
      bidInfo.map((e) => {
        return e.bids;
      })
    );
    // console.log(bidderInfo);
    let data = [];
    // let xData = xAxisLabel.filter((e) => e != null);

    xAxisLabel.forEach((e) => {
      let bidder = 0;

      bidderInfo.forEach((ele, index) => {
        // if (e.toLowerCase() === ele.country_name.toLowerCase()) {
        if (e === ele.country_name) {
          bidder = bidder + ele.bidders;
        }
      });
      data.push(bidder);
    });

    setTotalBidders(data);
  }, [bidInfo, bidderInfo]);

  const chartData = {
    labels: xAxisLabel,
    datasets: [
      {
        type: "bar",
        label: "Total Bids",
        data: totalBid,
        backgroundColor: "rgb(226, 252, 0 ,0.6)",
        borderColor: "rgb(226, 252, 0 ,0.6)",
      },
      {
        label: "Total Bidders",
        data: totalBidder,
        backgroundColor: "rgb(252, 0, 149,0.6)",
        borderColor: "rgb(252, 0, 149,0.6)",
      },
    ],
  };
  return (
    <div className={`${styles.chart_backgound} ${styles.flex}`}>
      <h3 className={`${styles.lable} `}>Bid & Bidders by Country</h3>
      <div className={`${styles.d_flex} `}>
        <SelectDate
          values={auctionTime}
          heading={"Auction"}
          setTimeDate={setTimeDate}
        />
      </div>
      <CountryChart chartData={chartData} />
    </div>
  );
}

export default BidByCountry;
