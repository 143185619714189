import React from "react";
import styles from "../assets/css/main.module.css";
import RegisterForm from "./RegisterForm";
function Register(props) {
  document.title = "Register";
  const style = `${styles.txtstyle} ${styles.mg_l}`;
  const changeState = () => {
    props.setFormState("login");
  };
  return (
    <div>
      <h2 className={style}>Hello!</h2>
      <RegisterForm />
      <p className={styles.txt}>
        Back to{" "}
        <button className={styles.switchbtn} onClick={() => changeState()}>
          Login
        </button>
      </p>
    </div>
  );
}

export default Register;
