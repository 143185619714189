import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styles from "./styles.module.css";
import {
  ACTIVE_AUCTIONS_API,
  AUCTION_BASELINK,
  TABLE_ROW_PER_PAGE,
} from "../helper/constant";
import axios from "axios";
import AuctionTable from "./AuctionTable";
function ActiveAuctions({ title, colTitles }) {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [auctionPerPage] = useState(TABLE_ROW_PER_PAGE);
  const pageNumbers = [];
  const [filterTableData, setFilterTableData] = useState([]);
  for (
    let i = 1;
    i <= Math.ceil(filterTableData.length / auctionPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    setLoading(true);
    const data = [];
    // console.log(AUCTION_BASELINK + ACTIVE_AUCTIONS_API);
    const fetchData = async () => {
      setLoading(true);
      const res = await axios.get(AUCTION_BASELINK + ACTIVE_AUCTIONS_API);
      // const res1 = await axios.get(
      //   "https://admin.thebig.deals/rest/V1/products/product-1"
      // );
      // console.log("new link data", res1.data);
      data.push(res.data);
      setAuctions(data);
      setLoading(false);
    };
    fetchData();
  }, []);
  useEffect(() => {
    setFilterTableData(auctions);
    // console.log(filterTableData);
  }, [auctions]);
  const filterData = (e) => {
    setFilterTableData(
      auctions.filter((data) =>
        JSON.stringify(Object.values(data))
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };
  const indexOfTheLastPost = currentPage * auctionPerPage;
  const indexOfTheFirstPost = indexOfTheLastPost - auctionPerPage;
  const currentAuctions = filterTableData.slice(
    indexOfTheFirstPost,
    indexOfTheLastPost
  );

  const handlePageClick = (clickedPageNumber) => {
    setCurrentPage(clickedPageNumber.selected + 1);
  };

  return (
    <div className={styles.auctions_details}>
      <div className={`${styles.auction_div_title}`}>
        <h2 className={`${styles.title_txt} ${styles.auction_title}`}>
          {title}
        </h2>
        <div className={`${styles.table_searchbox_div}`}>
          <input
            type="text"
            className={`${styles.table_searchbox}`}
            placeholder=" Search......"
            onChange={filterData}
          />
        </div>
      </div>
      <AuctionTable colTitles={colTitles} data={currentAuctions} />
      <div className={styles.paginationsdiv}>
        <ReactPaginate
          previousLabel={" <"}
          nextLabel={"> "}
          breakLabel={"..."}
          pageCount={pageNumbers.length}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={styles.paginations}
          pageClassName={styles.page_number_box}
          breakClassName={styles.page_number_box}
          previousClassName={styles.pre_next_div}
          nextClassName={styles.pre_next_div}
          activeClassName={styles.active_page}
          pageLinkClassName={styles.page_links}
          previousLinkClassName={styles.page_links}
          nextLinkClassName={styles.page_links}
          breakLinkClassName={styles.page_links}
        />
      </div>
    </div>
  );
}

export default ActiveAuctions;
