import React from "react";
import styles from "./styles.module.css";
function AuctionInfoTable({ filterAuctionInfo }) {
  return (
    <div className={`${styles.mg_t15}`}>
      <table className={`${styles.auctiontable}`}>
        <thead>
          <tr>
            <th>Bid Time</th>
            <th>Name</th>
            <th>Bid Price</th>
          </tr>
        </thead>
        <tbody>
          {filterAuctionInfo.map((element, index) => {
            return (
              <tr key={index}>
                <td>{element.bid_time}</td>
                <td>{`${element.country_code} : ${element.bidder_name}`}</td>
                <td>${element.current_bid_price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AuctionInfoTable;
