import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJs } from "chart.js/auto";
import mainStyle from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
function Chart({ chartData }) {
  return (
    <div className={`${styles.chart_div} `}>
      <Line data={chartData} />
    </div>
  );
}
// ${mainStyle.mg_l65}
export default Chart;
