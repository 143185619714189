import React, { useEffect, useState } from "react";
import MainHeader from "./Header";
import styles from "./styles.module.css";
import Cards from "./Cards";
import { AuctionDetails } from "./AuctionDetails";
import FutureAuctions from "./FutureAuctions";
import ActiveAuctions from "./ActiveAuctions";
import Helmet from "react-helmet";
const DashBoardBody = () => {
  const colTitles = ["Time", "Name", "Total Bids", "Actions"];
  const colTitlesOfPastAuctions = [
    "Time",
    "Name",
    "Winning Price",
    "Total Bids",
    "Actions",
  ];
  const data = [
    {
      time: "15/03/2022 , 20:30 IST",
      name: "iPhone Auction",
      bid: "1000",
    },
    {
      time: "15/03/2022 , 20:30 IST",
      name: "Car Auction",
      bid: "1000",
    },
    {
      time: "15/03/2022 , 20:30 IST",
      name: "iPhone Auction",
      bid: "1000",
    },
    {
      time: "15/03/2022 , 20:30 IST",
      name: "iPhone Auction",
      bid: "1000",
    },
  ];

  return (
    <div className={`${styles.dashboard_body}`}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <MainHeader />
      <Cards />
      <ActiveAuctions title="Active Auctions" colTitles={colTitles} />
      <AuctionDetails
        title="Past Auctions"
        colTitles={colTitlesOfPastAuctions}
        // data={currentAuctions}
      />

      <FutureAuctions
        title="Future Auctions"
        colTitles={colTitles}
        data={data}
      />
    </div>
  );
};

export default DashBoardBody;
