import React, { useState } from "react";
import Card from "./Card";
import styles from "./styles.module.css";
import { RiUser6Fill, RiAuctionFill } from "react-icons/ri";
import { FaBitcoin } from "react-icons/fa";
const Cards = () => {
  const cardDetails = [
    {
      title: "Total Users",
      cardcolor: styles.card_color_one,
      value: "12.65K+",
    },
    {
      title: "Total Auctions",
      cardcolor: styles.card_color_two,
      value: "1500+",
    },
    {
      title: "Total Bids",
      cardcolor: styles.card_color_three,
      value: "22.35K+",
    },
    {
      title: "Revenue",
      cardcolor: styles.card_color_four,
      value: "$45.05K+",
    },
  ];
  return (
    <div className={styles.cardsdiv}>
      {cardDetails.map((element, index) => {
        return (
          <Card
            key={index}
            title={element.title}
            cardcolor={element.cardcolor}
            value={element.value}
          />
        );
      })}
    </div>
  );
};

export default Cards;
