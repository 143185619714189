import React from "react";
import EditProductForm from "./EditProductForm";
import FormHeader from "./FormHeader";
import thumbnailImage from "../../assets/images/iphone.png";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
function EditProduct() {
  const location = useLocation();
  const data = location.state;

  return (
    <div>
      <Helmet>
        <title>Edit Product</title>
      </Helmet>
      <FormHeader title={"Edit Product"} />
      <EditProductForm data={data} />
    </div>
  );
}

export default EditProduct;
