import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
import * as Yup from "yup";
import InputError from "../InputError";
import axios from "axios";
import { ADD_NEW_PRODUCT, AUCTION_BASELINK } from "../helper/constant";
import { useNavigate } from "react-router-dom";
import Loading from "../helper/Loading";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
function ProductForm() {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    status: false,
    productName: "",
    sku: "",
    image_url: "",
    video: "",
    preRegister: false,
    minRegistrationToAuction: "",
    overheadCost: "",
    bidInc: "",
    timer: "",
    date: "",
    buyNowPrice: "",
    retailPrice: "",
    productUrl: "",
    play: "",
  };
  //Yup validation for form
  const validationSchema = Yup.object({
    productName: Yup.string().required("Required"),
    date: Yup.date().required("Required"),
    timer: Yup.number().required("Required"),
    play: Yup.string().required("Required"),
    overheadCost: Yup.number().required("Required"),
    retailPrice: Yup.number().required("Required"),
    productUrl: Yup.string().required("Required"),
    buyNowPrice: Yup.number().required("Required"),
    bidInc: Yup.string().required("Required"),
    image_url: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    // console.log(values);
    let status = "";
    let pre_registration = "";
    //checks  pre_registration an status and sets string value accordingly
    if (values.status) {
      status = "Y";
    } else {
      status = "N";
    }
    if (values.preRegister) {
      pre_registration = "Y";
    } else {
      pre_registration = "N";
    }
    console.log(pre_registration, status);
    // console.log(image_url);

    const data = {
      image_url: values.image_url,
      pre_registration,
      status,
      bid_increment: values.bidInc,
      buy_now_price: values.buyNowPrice,
      min_registration_to_start: values.minRegistrationToAuction,
      overhead_cost: values.overheadCost,
      product_url: values.productUrl,
      name: values.productName,
      retail_price: values.retailPrice,
      sku: values.sku,
      auction_start_time: values.date,
      video: values.video,
      bid_play: values.play,
      bid_timer: values.timer,
    }; //new product data
    // console.log(data);
    resetForm({ values: "" }); // form reset
    axios.post(AUCTION_BASELINK + ADD_NEW_PRODUCT, data).then((res) => {
      console.log(res);
      setLoading(false);
    });
  };

  return (
    <div className={styles.product_form_div}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formPros) => {
          return (
            <Form>
              <table className={`${styles.form_tabel} ${styles.align_left}`}>
                <tbody>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="status">Product Status</label>
                    </td>
                    <td>
                      <label className={styles.switch}>
                        <Field type="checkbox" name="status" />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      <ErrorMessage name="status" component={InputError} />
                    </td>

                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="preRegister">Pre Registrations?</label>
                    </td>
                    <td>
                      <label className={styles.switch}>
                        <Field type="checkbox" name="preRegister" />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      <ErrorMessage name="preRegister" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="productName">Product Name</label>
                    </td>
                    <td>
                      <Field name="productName" className={styles.fileds} />
                      <ErrorMessage name="productName" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="sku">SKU</label>
                    </td>
                    <td>
                      <Field name="sku" className={styles.fileds} />
                      <ErrorMessage name="sku" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="video">Video</label>
                    </td>
                    <td>
                      <Field name="video" className={styles.fileds} />
                      <ErrorMessage name="video" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="minRegistrationToAuction">
                        Min Registration To Start Auction
                      </label>
                    </td>
                    <td>
                      <Field
                        name="minRegistrationToAuction"
                        className={styles.fileds}
                      />
                      <ErrorMessage
                        name="minRegistrationToAuction"
                        component={InputError}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="overheadCost"> Overhead Cost</label>
                    </td>
                    <td>
                      <Field name="overheadCost" className={styles.fileds} />
                      <ErrorMessage
                        name="overheadCost"
                        component={InputError}
                      />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="bidInc">Bid Increment</label>
                    </td>
                    <td>
                      <Field name="bidInc" className={styles.fileds} />
                      <ErrorMessage name="bidInc" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="timer">Bid Timer</label>
                    </td>
                    <td>
                      <Field name="timer" className={styles.fileds} />
                      <ErrorMessage
                        type="number"
                        name="timer"
                        component={InputError}
                      />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="date">Auction Start Date Time</label>
                    </td>
                    <td>
                      {/* MUI DatePicker */}
                      <div className={styles.date_picker}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            onChange={(newValue) => {
                              var toUTC = new Date(newValue).toUTCString();
                              formPros.values.date = toUTC;
                              console.log(formPros.values.date);
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <ErrorMessage name="date" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="buyNowPrice">Buy now price</label>
                    </td>
                    <td>
                      <Field name="buyNowPrice" className={styles.fileds} />
                      <ErrorMessage name="buyNowPrice" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="retailPrice">Retail Price</label>
                    </td>
                    <td>
                      <Field name="retailPrice" className={styles.fileds} />
                      <ErrorMessage name="retailPrice" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="productUrl">Product URL</label>
                    </td>
                    <td>
                      <Field name="productUrl" className={styles.fileds} />
                      <ErrorMessage name="productUrl" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="play">Bid x Play</label>
                    </td>
                    <td>
                      <Field name="play" className={styles.fileds} />
                      <ErrorMessage name="play" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="4" className={` ${styles.labeltxt} `}>
                      <label htmlFor="image">Image</label>

                      <input
                        type="file"
                        name="image_url"
                        className={styles.filebtn}
                        onChange={(e) => {
                          //image file into base64
                          const reader = new FileReader();
                          reader.readAsDataURL(e.target.files[0]);
                          reader.onload = () => {
                            formPros.setFieldValue(
                              "image_url",
                              reader.result.toString()
                            );
                          };
                        }}
                      />
                      <ErrorMessage name="image_url" component={InputError} />
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              {loading ? (
                <div className={mainStyles.d_flex_center}>
                  <Loading />
                </div>
              ) : (
                <button type="submit" className={`  ${styles.btn}`}>
                  submit
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ProductForm;
