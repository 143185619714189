import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASEAPILINK, GET_ADMIN_DETAILS, userLogout } from "./helper/constant";
import NavList from "./NavList";
import ProfileBox from "./ProfileBox";
import styles from "../assets/css/main.module.css";
import axios from "axios";
import { isObject } from "formik";
function SideMenu() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstname: "Akshat",
    lastname: "Patel",
    email: "akshat06@yopmail.com",
  });
  const [info, setInfo] = useState({});
  const userToken = localStorage.getItem("token");
  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userToken != null);
  // console.log(userDetails);
  // useEffect(() => {
  //   // setInfo(userDetails);

  //   // const empty = () => {
  //   //   for (var firstname in userDetails) {
  //   //     return false;
  //   //   }
  //   //   return true;
  //   // };
  //   // const isEmpty = empty();
  //   // console.log(isEmpty);

  //   // if (userToken != null) {
  //   //   console.log("login");
  //   //   if (userDetails == null || isEmpty) {
  //   //     console.log("get user Details");
  //   //     const headers = {
  //   //       Authorization: `Bearer ${userToken}`,
  //   //     };
  //   //     axios
  //   //       .get(BASEAPILINK + GET_ADMIN_DETAILS, {
  //   //         headers: headers,
  //   //       })
  //   //       .then((res) => {
  //   //         console.log(res.data);
  //   //         setInfo({
  //   //           firstname:
  //   //             res.data.firstname.charAt(0).toUpperCase() +
  //   //             res.data.firstname.slice(1),
  //   //           lastname:
  //   //             res.data.lastname.charAt(0).toUpperCase() +
  //   //             res.data.lastname.slice(1),
  //   //           email: res.data.email,
  //   //         });
  //   //         localStorage.setItem(
  //   //           "userDetails",
  //   //           JSON.stringify({
  //   //             firstname:
  //   //               res.data.firstname.charAt(0).toUpperCase() +
  //   //               res.data.firstname.slice(1),
  //   //             lastname:
  //   //               res.data.lastname.charAt(0).toUpperCase() +
  //   //               res.data.lastname.slice(1),
  //   //             email: res.data.email,
  //   //           })
  //   //         );
  //   //       });
  //   //   } else {
  //   //     setInfo(JSON.parse(userDetails));
  //   //   }
  //   // } else {
  //   //   navigate("/login");
  //   // }
  // }, []);
  // useEffect(() => {
  //   console.log(info);

  //   setUser({
  //     firstname :"Akshat",
  //     lastname : "Patel",
  //     email:"akshat06@yopmail.com"
  //   });
  // }, [info]);
  return (
    <div className={styles.sidemenu}>
      <ProfileBox
        firstname={user.firstname}
        lastname={user.lastname}
        email={user.email}
      />
      <NavList />
    </div>
  );
}

export default SideMenu;
