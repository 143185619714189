import React from "react";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
function SelectDate({ values, heading, setTimeDate }) {
  return (
    <div>
      <select
        className={`${styles.select_div}  ${mainStyles.mg_t20}`}
        onChange={(e) => {
          console.log(e.target.value);
          setTimeDate(e.target.value);
        }}
      >
        <option value="0" disabled="disabled" selected="true">
          {heading}
        </option>

        {values.map((e, index) => {
          return (
            <option key={index} value={e.id}>
              {e.date.replace("(India Standard Time)", "")}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default SelectDate;
