import React from "react";
import mainStyles from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
function UserAuctionTable() {
  return (
    <div className={styles.user_auction_div}>
      <p className={`${styles.title} ${styles.left}`}>participated auction</p>
      <table className={styles.user_table}>
        <thead>
          <tr>
            <th></th>
            <th>Auction Name</th>
            <th> Date</th>
            <th>User Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Iphone 13 pro</td>
            <td> Thu Apr 21 2021 18:30:00</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>2</td>
            <td>BMW Car</td>
            <td> Thu Jan 02 2022 10:30:00</td>
            <td>Won</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default UserAuctionTable;
