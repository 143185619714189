import React from "react";
import styles from "../assets/css/main.module.css";
import LoginForm from "./LoginForm";
function Login(props) {
  document.title = "Login";
  const style = `${styles.txtstyle} ${styles.mg_l}`;

  const changeState = () => {
    props.setFormState("register");
    console.log(props);
  };
  return (
    <div className={styles.logindiv}>
      <h2 className={style}>Welcome!</h2>
      <LoginForm />

      {/* <p className={styles.txt}>
        Not a Member yet? to Create Account{" "}
        <button className={styles.switchbtn} onClick={() => changeState()}>
          Click Here
        </button>
      </p> */}
    </div>
  );
}

export default Login;
