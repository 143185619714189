import React from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import UserInfo from "./UserInfo";

function UserBody() {
  return (
    <div>
      {/* <Header /> */}
      <Helmet>
        <title>Users</title>
      </Helmet>
      <UserInfo />
    </div>
  );
}

export default UserBody;
