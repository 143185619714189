import React from "react";
import Helmet from "react-helmet";
import mainStyles from "../../assets/css/main.module.css";
import BidByCountry from "./BidByCountry";
import PriceBandChart from "./PriceBandChart";
import styles from "./styles.module.css";
import TimeDurationChart from "./TimeDurationChart";
function AnalyticsBody() {
  return (
    <div className={styles.analytics_div}>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <div className={`${mainStyles.headerDiv}`}>
        <div className={`${mainStyles.mg_t40}`}>
          <p className={mainStyles.title_txt}> Analytics</p>
        </div>
      </div>

      <div className={`${styles.all_charts_div}`}>
        <TimeDurationChart />
        <PriceBandChart />
      </div>
      <div className={styles.country_chart_div}>
        <BidByCountry />
      </div>
    </div>
  );
}

export default AnalyticsBody;
