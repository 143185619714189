import React, { useState } from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import ProductListData from "./ProductListData";

function ProductsBody() {
  return (
    <div>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Header />
      <ProductListData />
    </div>
  );
}

export default ProductsBody;
