import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import styles from "../assets/css/main.module.css";
function Home() {
  const userToken = localStorage.getItem("token");

  return (
    <div className={styles.dashboard}>
      <SideMenu />
      {userToken !== null ? <Outlet /> : <Navigate to="/login" />}
    </div>
  );
}

export default Home;
