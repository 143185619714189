import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import mainStyles from "../../assets/css/main.module.css";
import * as Yup from "yup";
import InputError from "../InputError";
import axios from "axios";
import { AUCTION_BASELINK, UPDATE_PRODUCTS } from "../helper/constant";
import { useNavigate } from "react-router-dom";
import Loading from "../helper/Loading";
import { MdCancel } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
//jnco0qis99fmkeepskgykc3ui993vppo
function EditProductForm({ data }) {
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);
  const [imageChange, setImageChange] = useState(false);
  const [value, setValue] = useState(new Date(data.auction_start_time));
  let preRegister = false;
  let status = false;
  // console.log(data);
  // console.log(data.image_url != null);

  //checks  pre_registration an status and sets bool value accordingly for form checkbox
  if (data.status === "Y") {
    status = true;
  } else {
    status = false;
  }
  if (data.pre_registration === "Y") {
    preRegister = true;
  } else {
    preRegister = false;
  }

  const [oldData, setOldData] = useState({
    id: data.ID,
    status,
    productName: data.name,
    sku: data.sku,
    video: data.video,
    preRegister,
    minRegistrationToAuction: data.min_registration_to_start,
    overheadCost: data.overhead_cost,
    bidInc: data.bid_increment,
    timer: data.bid_timer,
    date: data.auction_start_time,
    buyNowPrice: data.buy_now_price,
    retailPrice: data.retail_price,
    productUrl: data.retail_price,
    play: data.bid_play,
    image_url: "",
  });
  //yup validations
  const validationSchema = Yup.object({
    productName: Yup.string().required("Required"),
    date: Yup.date().required("Required"),
    timer: Yup.number().required("Required"),
    play: Yup.number().required("Required"),
    overheadCost: Yup.number().required("Required"),
    retailPrice: Yup.number().required("Required"),
    productUrl: Yup.string().required("Required"),
    buyNowPrice: Yup.number().required("Required"),
    bidInc: Yup.number().required("Required"),
    // image_url: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    setLoding(true);
    let status = "";
    let pre_registration = "";
    //checks  pre_registration an status and sets string value accordingly

    if (values.status) {
      status = "Y";
    } else {
      status = "N";
    }
    if (values.preRegister) {
      pre_registration = "Y";
    } else {
      pre_registration = "N";
    }
    // console.log(pre_registration, status);
    // console.log(values.image_url);

    const updatedData = {
      ID: values.id,
      image_url: values.image_url,
      pre_registration,
      status,
      bid_increment: values.bidInc,
      buy_now_price: values.buyNowPrice,
      min_registration_to_start: values.minRegistrationToAuction,
      overhead_cost: values.overheadCost,
      product_url: values.productUrl,
      name: values.productName,
      retail_price: values.retailPrice,
      sku: values.sku,
      auction_start_time: values.date,
      video: values.video,
      bid_play: values.play,
      bid_timer: values.timer,
    };
    //checks if old image is changed or not if no change in image then set old image to updatedData obj
    if (data.image_url != null) {
      updatedData.image_url = data.image_url;
    }
    console.log(updatedData);

    axios.put(AUCTION_BASELINK + UPDATE_PRODUCTS, updatedData).then((res) => {
      console.log(res);
      setLoding(false);
      navigate("/products");
    });
  };
  // deleting old image
  useEffect(() => {
    if (imageChange) {
      console.log("empty image");
      data.image_url = null;
      console.log(data);
    }
  }, [imageChange]);
  return (
    <div className={styles.product_form_div}>
      <Formik
        initialValues={oldData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formPros) => {
          // console.log(formPros);
          return (
            <Form>
              <table className={`${styles.form_tabel} ${styles.align_left}`}>
                <tbody>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="status">Product Status</label>
                    </td>
                    <td>
                      <label className={styles.switch}>
                        <Field type="checkbox" name="status" />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      <ErrorMessage name="status" component={InputError} />
                    </td>

                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="preRegister">Pre Registrations?</label>
                    </td>
                    <td>
                      <label className={styles.switch}>
                        <Field type="checkbox" name="preRegister" />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      <ErrorMessage name="preRegister" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="productName">Product Name</label>
                    </td>
                    <td>
                      <Field
                        id="ProductName"
                        name="productName"
                        className={styles.fileds}
                      />
                      <ErrorMessage name="productName" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="sku">SKU</label>
                    </td>
                    <td>
                      <Field name="sku" className={styles.fileds} />
                      <ErrorMessage name="sku" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="video">Video</label>
                    </td>
                    <td>
                      <Field name="video" className={styles.fileds} />
                      <ErrorMessage name="video" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="minRegistrationToAuction">
                        Min Registration To Start Auction
                      </label>
                    </td>
                    <td>
                      <Field
                        name="minRegistrationToAuction"
                        className={styles.fileds}
                      />
                      <ErrorMessage
                        name="minRegistrationToAuction"
                        component={InputError}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="overheadCost"> Overhead Cost</label>
                    </td>
                    <td>
                      <Field name="overheadCost" className={styles.fileds} />
                      <ErrorMessage
                        name="overheadCost"
                        component={InputError}
                      />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="bidInc">Bid Increment</label>
                    </td>
                    <td>
                      <Field name="bidInc" className={styles.fileds} />
                      <ErrorMessage name="bidInc" component={InputError} />
                    </td>
                  </tr>
                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="timer">Bid Timer</label>
                    </td>
                    <td>
                      <Field name="timer" className={styles.fileds} />
                      <ErrorMessage
                        type="number"
                        name="timer"
                        component={InputError}
                      />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="date">Auction Start Date Time</label>
                    </td>
                    <td>
                      <div className={styles.date_picker}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            value={value}
                            onChange={(newValue) => {
                              var toUTC = new Date(newValue).toUTCString();
                              formPros.values.date = toUTC;
                              console.log(formPros.values.date);
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <ErrorMessage name="date" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="buyNowPrice">Buy now price</label>
                    </td>
                    <td>
                      <Field name="buyNowPrice" className={styles.fileds} />
                      <ErrorMessage name="buyNowPrice" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="retailPrice">Retail Price</label>
                    </td>
                    <td>
                      <Field name="retailPrice" className={styles.fileds} />
                      <ErrorMessage name="retailPrice" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="productUrl">Product URL</label>
                    </td>
                    <td>
                      <Field name="productUrl" className={styles.fileds} />
                      <ErrorMessage name="productUrl" component={InputError} />
                    </td>
                    <td className={` ${styles.labeltxt}`}>
                      <label htmlFor="play">Bid x Play</label>
                    </td>
                    <td>
                      <Field name="play" className={styles.fileds} />
                      <ErrorMessage name="play" component={InputError} />
                    </td>
                  </tr>

                  <tr>
                    <td className={` ${styles.labeltxt} `}>
                      <label htmlFor="image">Image</label>
                    </td>
                    <td>
                      {/* shows old image first */}
                      {data.image_url != null && !imageChange ? (
                        <div
                          onClick={() => {
                            setImageChange(true);
                          }}
                          className={`${styles.edit_image_div}`}
                        >
                          <img
                            src={AUCTION_BASELINK + data.image_url.slice(1)}
                            alt="product image"
                            className={`${styles.thumbnail_image} ${styles.mg_l0}`}
                          />
                          <div className={`${styles.image_hover_div}`}>
                            <MdCancel />
                          </div>
                        </div>
                      ) : (
                        <input
                          type="file"
                          name="image_url"
                          className={styles.filebtn}
                          onChange={(e) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(e.target.files[0]);
                            reader.onload = () => {
                              formPros.setFieldValue(
                                "image_url",
                                reader.result.toString()
                              );
                            };
                          }}
                        />
                      )}
                      <ErrorMessage name="image_url" component={InputError} />
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>

              {loading ? (
                <div className={mainStyles.d_flex_center}>
                  <Loading />
                </div>
              ) : (
                <button type="submit" className={`  ${styles.btn}`}>
                  submit
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default EditProductForm;
