export const BASEAPILINK = "https://admin.thebig.deals/rest";
export const TABLE_ROW_PER_PAGE = 10;
export const userLogout = () => {
  const userOut = {
    saveUser: false,
  };
  localStorage.setItem("user", JSON.stringify(userOut));
  localStorage.removeItem("token");
  localStorage.removeItem("userDetails");
  return true;
};
export const GET_ADMIN_DETAILS = "/V1/customers/me";
export const PRODUCT_DETAILS_API = "/auction-details/product-";
export const AUCTION_BASELINK = "https://node.thebig.deals";
export const GET_AUCTIONS_API = "/get-auctions";
export const ACTIVE_AUCTIONS_API = "/auction-details/product-1";
export const UPCOMING_AUCTIONS_API = "/auction-details/product-1";
export const GET_BIDDERS_BID_BY_TIME_INTERVAL =
  "/get-bidders-bid-count-by-time-interval";
export const GET_USER_PER_PRICE = "/get-users-per-price-band";
export const GET_BIDDERS_TOTAL_BIDS_BY_COUNTRY =
  "/get-bidders-total-bids-by-country";
export const GET_BIDLOG = "/get-bid-log-by-auction";
export const GET_PRODUCTS = "/products";
export const ADD_NEW_PRODUCT = "/add-auction-product";
export const UPDATE_PRODUCTS = "/products";
export const DELETE_PRODUCTS = "/products?id=";
export const GET_USERS = "/users";
