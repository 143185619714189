import React from "react";
import styles from "../../assets/css/main.module.css";
function Loading() {
  return (
    <div className={`${styles.dot_div}`}>
      <div className={`${styles.dot_one}`}></div>
      <div className={`${styles.dot_two}`}></div>
      <div className={`${styles.dot_three}`}></div>
    </div>
  );
}

export default Loading;
