import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import mainStyles from "../../assets/css/main.module.css";
import styles from "./styles.module.css";
import UserTable from "./UserTable";
import {
  AUCTION_BASELINK,
  GET_USERS,
  TABLE_ROW_PER_PAGE,
} from "../helper/constant";
import Loading from "../helper/Loading";
function UserInfo() {
  const [currentPage, setCurrentPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handlePageClick = (clikedPageNumber) => {
    setCurrentPage(clikedPageNumber.selected);
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        AUCTION_BASELINK +
          GET_USERS +
          `?page_num=${currentPage}&page_record_limit=${TABLE_ROW_PER_PAGE}`
      )
      .then((res) => {
        // console.log(res.data.data);
        setUserData(res.data);
        setPageCount(res.data.total_pages);
        setLoading(false);
      });
  }, [currentPage]);
  useEffect(() => {
    setFilteredData(userData.data);
  }, [userData]);

  const filterData = (e) => {
    setFilteredData(
      userData.data.filter((data) =>
        JSON.stringify(Object.values(data))
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };
  return (
    <div className={`${mainStyles.mg_t40} `}>
      <div className={`${styles.auction_div_title}  ${mainStyles.headerdiv}`}>
        <p className={mainStyles.title_txt}>Users</p>
        <div className={`${styles.table_searchbox_div}`}>
          <input
            type="text"
            className={`${styles.table_searchbox} `}
            placeholder=" Search......"
            onChange={filterData}
          />
        </div>
      </div>
      <div className={styles.user_table_div}>
        {loading ? (
          <div className={mainStyles.d_flex_center}>
            <Loading />
          </div>
        ) : (
          <UserTable data={filteredData} />
        )}
        <div className={mainStyles.paginationsdiv}>
          <ReactPaginate
            previousLabel={" <"}
            nextLabel={"> "}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={mainStyles.paginations}
            pageClassName={mainStyles.page_number_box}
            breakClassName={mainStyles.page_number_box}
            previousClassName={mainStyles.pre_next_div}
            nextClassName={mainStyles.pre_next_div}
            activeClassName={mainStyles.active_page}
            pageLinkClassName={mainStyles.page_links}
            previousLinkClassName={mainStyles.page_links}
            nextLinkClassName={mainStyles.page_links}
            breakLinkClassName={mainStyles.page_links}
          />
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
