import React from "react";
import disp from "../assets/images/disp.jpg";
import styles from "../assets/css/main.module.css";
function WelcomeImage() {
  // console.log(disp);
  return (
    <div className={styles.imagediv}>
      <img src={disp} className={styles.imagesize} />
    </div>
  );
}

export default WelcomeImage;
